<template>
  <sub-page
      :title="$t('Settings')"
      v-model="$store.state.newsletter.emailPageData"
      header-route="newsletter"
      :header-action-text="$t('Save')"
      :header-action="save"
  >

    <ws-accordion
        :items="navigationItems"
        class="mt-5"
    >
      <template #item.contacts>
        <div class="pa-5 pb-8 pl-8">
          <ws-phone-input
              v-model="entityData.phone"
              width="500"
              :label="$t('Telephone')"
          />
          <ws-text-field
              v-model="entityData.email"
              width="500"
              :label="$t('Email')"
          />
          <ws-text-field
              v-model="entityData.address"
              width="500"
              class="mt-5"
              :label="$t('Address')"
              clearable
              area
          />
        </div>


      </template>
    </ws-accordion>


  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "newslettersTable",
  data() {
    return {
      entityData : {}
    }
  },
  computed : {
    navigationItems() {
      return [
        { name : this.$t('Contacts') , subtitle : this.$t('newsletter.email.settings.description'), value : 'contacts', icon : 'mdi-cog' , expanded : true }
      ]
    }
  },
  methods : {
    ...mapActions('newsletter' , [
        'GET_DEFAULT_SETTINGS',
        'SAVE_DEFAULT_SETTINGS'
    ]),

    async save() {
      let result = this.SAVE_DEFAULT_SETTINGS(this.entityData)
      if ( !result ) {
        return
      }
      this.notify(this.$t('ChangesSaved'))

    },

    async initPage() {
      let result = await this.GET_DEFAULT_SETTINGS()
      if ( !result || result === true ) {
        return
      }
      this.entityData = result
    }

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>